#gameLibrary {
  max-width: 100vw;
}
#searchResAndForm {
  display: flex;
}

@media (max-width: $bp-tablet) {
  #searchResAndForm {
    margin: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.flexAside {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-content: center;
}

@media (min-width: $bp-tablet) {
  .gameLeaderboard {
    height: 220px;
    min-width: 250px;
    margin-top: 45px;
    margin-left: 22px;
    margin-bottom: 40px;
  }
}
