.heroContainer {
  position: relative;
  width: 100vw;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heroText {
  max-width: 85vw;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  display: inline-block;
  position: relative;
  padding: 15px;
  border: 1px solid $text-color;
  border-radius: 2px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -moz-backdrop-filter: blur(3px);

  h1 {
    font-size: 3rem;
    font-family: $font-heading;
    color: $text-color;
  }
  p {
    font-family: $font-main;
    font-size: 1.2rem;
    color: $text-color;
  }
}
.heroPic {
  position: absolute;
  width: 100%;
  height: 40vh;
  z-index: -1;
}
.heroPic::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../Images/TTGBackground.jpg');
  background-repeat: no repeat;
  background-size: cover;
  background-position: center center;
}

@media (min-width: $bp-mobile) {
  .heroContainer {
    height: 60vh;
  }
  .heroPic {
    height: 60vh;
  }
  .heroText {
    h1 {
      font-size: 4.8rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
}
