.loginContainer {
  display: flex;
  padding-top: 15px;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  h3 {
    font-family: $font-heading;
    // font-size: 1.5rem;
  }
  label {
    font-family: $font-main;
  }
  input {
    margin: 5px;
  }
  button {
    margin-top: 5px;
  }
  .resetBtn {
    margin-right: 5px;
  }
}

.login {
  background: $base-color;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.alreadyRegistered {
  margin-top: 15px;
}

.resetPasswordLink {
  margin-top: 15px;
}

@media (min-width: $bp-mobile) {
  .register {
    margin-right: auto;
    margin-left: auto;
    width: 300px;
  }
  .login {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
}

@media (min-width: $bp-tablet) {
  .login {
    width: 350px;
  }
  .register {
    width: 350px;
  }
}
