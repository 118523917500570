.resetPasswordPage {
  flex: 1 1 auto;
  text-align: center;
  .resetPasswordContainer {
    margin: 20px;
    padding: 30px;
    background: $base-color;
  }
  h3 {
    text-align: center;
    margin-bottom: 10px;
  }
  input {
    margin-bottom: 10px;
  }
}
