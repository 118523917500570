@import '../1-helpers/mixins';

.shelfItemBox {
  height: 220px;
  width: 80vw;
  min-width: 280px;
  display: relative;
  margin: 20px;
  padding: 10px;
  background: white;
  text-decoration: none;

  @include border();
  img {
    margin-top: 10px;
    height: 110px;
    object-fit: cover;
  }
  .nameFlex {
    display: flex;
    justify-content: center;
  }
  h3 {
    color: $text-color;
    display: inline-block;
    text-align: center;
    font-family: $font-heading;
    font-size: 1.2rem;
    margin: 5px 2px 10px 2px;
    padding: 2px 20px;
    background: $base-color;
    @include border();
  }
  h4 {
    font-size: 1rem;
  }
  p {
    font-family: $font-main;
    font-size: 0.8rem;
    color: $text-color;
  }
}
.shelf {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.shelfItemFlex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.linkContainer {
  display: relative;
}

.linkContainer:hover .infoText {
  opacity: 1;
}
.linkContainer:hover .shelfImage {
  opacity: 0.4;
}

.overlay {
  position: relative;
}

.infoText {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 150%);

  text-decoration: none;
  padding: 10px;
  background: $base-color;
  opacity: 0;
  transition: 0.5ms;
  border-radius: 1px;
  color: $text-color;
}
.infoText {
  transition: 0.5s ease;
}

.shelfImage {
  max-width: 100%;
  object-fit: contain;
  transition: 0.5s ease; 
}

.stats {
  display: relative;
  max-width: 45%;
  max-height: 100%;
  margin: 10px 0 10px 10px;
  padding: 15px;
  @include border();
  background: $base-color;
  h4 {
    font-family: $font-heading;
    text-align: center;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 10px;
  }
}

.rating {
  p {
    display: inline-block;
    margin-right: 5px;
  }
}

@media (min-width: $bp-mobile) {
  .shelfItemBox {
    width: 350px;
    margin: 20px;
    h3 {
      font-size: 1.4rem;
    }
    h4 {
      font-size: 1.2rem;
    }
  }
}

@media (min-width: $bp-tablet) {
  .shelf {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  .shelfItemBox {
    width: 400px;
  }
}
