$font-heading: 'Cinzel', serif;
$font-main: 'Raleway', sans-serif;

$base-color: #f0f0f0;
$secondary-color: #bebdbd;
$text-color: #2b2b2b;
$highlight-color: #91360c;

$bp-mobile: 455px;
$bp-tablet: 768px;
