canvas {
  max-width: 100%;
}

@media (max-width: $bp-tablet) {
  canvas {
    width: 80vw;
    margin: auto;
  }
}
