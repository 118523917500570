h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  text-align: center;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}
